// @flow

import {getFloorFromUnit, getPropertyNameFromUnit, flatten} from '@util';

import type {FloorplanType, NeighborhoodType} from '@types-local';

export default function getAvailableUnitsForNeighborhood(
  floorplans: FloorplanType[],
  neighborhood: NeighborhoodType,
): Object[] | typeof undefined | boolean {
  if (floorplans) {
    if (Array.isArray(floorplans) && floorplans.length > 0) {
      const availableFloorplans = floorplans.filter(floorplan => {
        if (floorplan && floorplan.property) {
          return !floorplan.property.soldout;
        } else return null;
      });
      const availableUnits = availableFloorplans
        .map(floorplan => {
          if (floorplan.units) {
            const {units} = floorplan;
            const filteredUnits = units
              .filter(unit => unit.available)
              .map(unit => {
                const mergedUnitFloorplan = Object.assign({}, floorplan, unit);
                mergedUnitFloorplan.neighborhood =
                  floorplan.property.address.neighborhood[0].name;
                mergedUnitFloorplan.name = floorplan.name;
                mergedUnitFloorplan.propertyName = floorplan.property.name;
                delete mergedUnitFloorplan.units;
                const name = getPropertyNameFromUnit(mergedUnitFloorplan);
                if (name) {
                  const floor = getFloorFromUnit(mergedUnitFloorplan);
                  mergedUnitFloorplan.floor = floor;
                  mergedUnitFloorplan.name = name;
                  return mergedUnitFloorplan;
                } else return null;
              });
            return filteredUnits;
          } else return null;
        })
        .reduce(flatten, [])
        .filter(unit => unit?.neighborhood === neighborhood.name);
      if (availableUnits.length > 0) {
        // $FlowFixMe
        return availableUnits;
      }
    }
  }
}
