/* eslint-disable max-lines */

import React, {Component, Fragment} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import Layout from '@layouts';
import Section from '@components/layout/Section';
import Hero from '@components/Hero';
import ImageCallout from '@components/ImageCallout';
import PreFooter from '@components/PreFooter';
import Headline from '@components/Headline';
import CardGrid from '@components/CardGrid';
import FloorPlanCard from '@components/FloorPlanCard';
import Map from '@components/Map';
import SectionTitle from '@components/SectionTitle';
import Button from '@components/Button';
import {LIGHT_GREY, WHITE, ORANGE, BLUE} from '@styles/colors';
import getAvailableUnitsForNeighborhood from '@util/getAvailableUnitsForNeighborhood';
import {
  getAddressFromAddressSet,
  neighborhoodHasUnits,
  stringToUrl,
} from '@util';

import type {NeighborhoodType, FloorplanType, UnitType} from '@types-local';

type Props = {
  data: {
    wagtail: {
      ...NeighborhoodType,
      ...UnitType[],
      ...FloorplanType[],
    },
  },
};

type Amenity = {
  id: number,
  name: string,
  description: string,
  image: {
    url: string,
  },
};

type State = {
  randomSeed: number | null,
  neighborhoodUnits: NeighborhoodType[] | null,
};

function defineTheEndBasedOnTheName(name: string): string {
  switch (name.toLowerCase()) {
    case 'nomad':
      return `in ${name}`;
    case "hell's kitchen":
      return `in ${name}`;
    case 'noho':
      return `of ${name}`;
    default:
      return `on the ${name}`;
  }
}

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

function getNeighborhoodSpecificData(
  neighborhood: NeighborhoodType,
): {headline: string, description: string} {
  if (neighborhood && neighborhood.name) {
    switch (neighborhood.name.toLowerCase()) {
      case "hell's kitchen":
        return {
          headline: 'Find Your Spark in Hell’s Kitchen',
          description:
            'With Hudson Yards so close by, this genuine New York City neighborhood is always evolving and stays authentically modern by offering the latest in unforgettable entertainment, vibrant nightlife, and incredible dining. All fit into one perfect location.',
        };
      case 'nomad':
        return {
          headline: 'There’s No Place Like NoMad',
          description:
            'With absolutely everything you could ever desire from New York City living right at your fingertips, NoMad is your convenient, trendy sanctuary in the center of it all.',
        };
      case 'upper east side':
        return {
          headline: 'The UES Does it Best',
          description:
            'If you’re after a neighborhood that provides small town charm and convenience without sacrificing that big town NYC vibe you know and love, the Upper East Side is the place for you.',
        };
      case 'noho':
        return {
          headline: 'NoHo or Nowhere',
          description:
            'This small downtown neighborhood offers the best of New York City living. Its convenient location with easy access to other parts of the city and its electric spirit rich in culture, it’s everything you’d want to be surrounded by and more.',
        };
    }
  }
}

function getTitleBasedOnEyebrow(eyebrow: string): string {
  if (eyebrow)
    switch (eyebrow.toLowerCase()) {
      case 'al fresco':
        return 'Live Outside the Box';
      case 'eclectic local shops':
        return 'Authentic at Every Corner';
      case 'entertainment':
        return 'you’re in for a show';
      case 'parks':
        return 'A Touch of Green';
      case 'museum mile':
        return 'Abuzz with Culture';
      case 'bars & restaurants':
        return 'A Little More Chill';
      case 'central locations':
        return 'Your World Within Reach';
      case 'food experiences':
        return 'For Those Hungry for a Little More';
      case 'arts and education':
        return 'Surrounded by Creativity';
      case 'culture packed':
        return 'Where creativity shines';
      case 'unique architecture':
        return 'A perfect mix of classic and cool';
      case 'convenience':
        return 'Be on your way without hassle';

    }
}

function getIndexForAmenities(amenitie){
  if (amenitie){
    switch (amenitie.toLowerCase()) {
      case 'entertainment':
        return 0;
      case 'al fresco':
        return 1;
      case 'eclectic local shops':
        return 2;
      case 'arts and education':
        return 0;
      case 'food experiences':
        return 1;
      case 'central locations':
        return 2;
      case 'museum mile':
        return 0;
      case 'parks':
        return 1;
      case 'bars & restaurants':
        return 2;
      case 'culture packed':
        return 0;
      case 'unique architecture':
        return 1;
      case 'convenience':
        return 2;
      default:
        return 0;
    }
  }
}

export default class Neighborhood extends Component<Props, State> {
  constructor(props) {
    super(props);
    const {data} = this.props;
    const {floorplans, neighborhood} = data.wagtail;
    const excludedUnits = ['345E94']; // Units to be excluded in Upper East Side
    this.state = {
      neighborhoodUnits: (getAvailableUnitsForNeighborhood(
        floorplans,
        neighborhood,
      ) || []).filter(unit => 
        unit && unit.property && 
        !(neighborhood?.name === 'Upper East Side' && excludedUnits.includes(unit.property.name))
      ),
      neighborhoodSpecificData: getNeighborhoodSpecificData(neighborhood),
    };
  }

  

  render() {
    const {data} = this.props;
    const {neighborhood, floorplans} = data.wagtail;
    const {
      name,
      neighborhoodpageSet,
      image,
      addressSet,
      amenities,
    } = neighborhood;
    const [{seoTitle, searchDescription}] = neighborhoodpageSet;
    const {neighborhoodSpecificData} = this.state;

    var orderedAmenities = [];
    if (amenities) {
      for (let index = 0; index < amenities.length; index++) {
        if (amenities[index]) {
          orderedAmenities[getIndexForAmenities(amenities[index].name)] = amenities[index];
        }
      }
    }

    const filteredProperties = (floorplans || [])
      .filter(floorplan => floorplan && floorplan.property)
      .filter(floorplan => !floorplan.property.soldout)
    .map(floorplan => floorplan.property)
    .map(property => ({
      propertyName: property.name,
      propertyNeighborhood: property.address.neighborhood && property.address.neighborhood[0] ? property.address.neighborhood[0].name : 'Unknown',
    }));
  

    const uniqueProperties = removeDuplicates(
      filteredProperties,
      'propertyName',
    );
    const uniqueProperty = uniqueProperties.filter(
      x => x && x.propertyNeighborhood === name,
    );    

    var propertyName = uniqueProperty?.[0]?.propertyName;
    /* Temporary fix, just to redirect to mulberry while it don't has units */
    if(!propertyName && neighborhood?.name == "NoHo"){
      propertyName = "298 Mulberry"
    }

    const sectionRef = React.createRef();

    return (
      <Fragment>
        <Helmet>
          <html lang="en" />
          <title>{seoTitle}</title>
          <meta name="description" content={searchDescription} />
        </Helmet>
        <Layout>
          <Hero
            title={
              neighborhoodSpecificData && neighborhoodSpecificData.headline
            }
            background={
              image &&
              image.url &&
              `linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3)), url(${
                image.url
              }) no-repeat`
            }
            body={
              neighborhoodSpecificData && neighborhoodSpecificData.description
            }
            scrollToLink
            copy={'Start Exploring'}
            sectionRef={sectionRef}
          />
          <div id="second-section" ref={sectionRef}></div>
          {orderedAmenities && orderedAmenities.length > 0
            ? orderedAmenities.map(
                (
                  {
                    name: amenityName,
                    image: amenityImage,
                    description,
                  }: Amenity,
                  index,
                ) => (
                  <Section key={amenityName}>
                    <ImageCallout
                      key={amenityName}
                      imageLeft={index % 2 === 0 ? false : true}
                      imageSrc={amenityImage.url}
                      eyebrow={amenityName}
                      headline={getTitleBasedOnEyebrow(amenityName)}
                      copy={description}
                      textAlign="left"
                      imgMaxHeight="600px"
                      imgMaxWidth="50%"
                    />
                  </Section>
                ),
              )
            : null}
          <Fragment>
            {neighborhood.name !== 'NoMad' && neighborhoodHasUnits(floorplans || [], neighborhood) && (
              <Section background={LIGHT_GREY}>
                <Headline>{`All Available Units ${defineTheEndBasedOnTheName(
                  name,
                )}`}</Headline>
                <CardGrid
                  data={this.state.neighborhoodUnits}
                  maxWidth="1360px"
                  gridItem={FloorPlanCard}
                  gridTemplateColumns={'repeat(auto-fit, minmax(300px, 1fr))'}
                  gridGap="40px"
                  margin="0 auto"
                  padding="0 20px"
                  itemBackground={WHITE}
                />
              </Section>
            )}
          </Fragment>
          {!['Upper East Side', "Hell's Kitchen", 'NoMad'].includes(neighborhood.name) && (
            <Section>
              <SectionTitle
                eyebrow="Nearby Things"
                dividerColor={ORANGE}
                headline={`Explore ${
                  name.toLowerCase() !== 'upper east side'
                    ? `${name}`
                    : `the ${name}`
                }`}
                copy={`Take a look around at just a sampling of what ${name} has to offer.`}
                margin="0 auto 40px auto"
              >
                <Button
                  buttonLink={`/properties/${stringToUrl(propertyName)}/`}
                  buttonText={`view ${propertyName}`}
                  buttonColor={BLUE}
                />
              </SectionTitle>
              <Map
                address={getAddressFromAddressSet(addressSet)}
                width="90%"
                neighborhood={neighborhood.name}
              />
            </Section>
          )}
          {!['Upper East Side', "Hell's Kitchen", 'NoMad'].includes(neighborhood.name) && (
            <PreFooter
              headline={`Interested in becoming a resident ${defineTheEndBasedOnTheName(
                name,
              )}?`}
              copy="Get in touch with us to find your perfect Fetner apartment."
            />
          )}
        </Layout>
      </Fragment>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wagtail {
      neighborhood(slug: $slug) {
        name
        neighborhoodpageSet {
          seoTitle
          searchDescription
        }
        amenities {
          name
          description
          image {
            url
          }
        }
        image {
          url
        }
        addressSet {
          street
          city
          state
          postalCode
        }
      }
      floorplans {
        id
        name
        beds
        baths
        squareFeet
        property {
          soldout
          name
          address {
            neighborhood {
              name
            }
          }
        }
        image {
          url
        }
        units {
          id
          name
          slug
          price
          available
          availableDate
        }
      }
    }
  }
`;